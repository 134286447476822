import { styled } from "@linaria/react";
import { Layout } from "@reifyhealth/picasso-pkg";
import {
  SelectedTrackContext,
  useSelectedTrack,
} from "@contexts/selectedTrack";
import { useBudget } from "@app/hooks";
import { useStore } from "@app/store";
import Loading from "@components/Loading";
import type { TableActionsProps } from "@app/pages/BudgetCreation2/TableActions";
import type { TableHeaderProps } from "@app/pages/BudgetCreation2/TableHeader";
import type { TableBodyProps } from "@app/pages/BudgetCreation2/TableBody";
import type { TableFooterProps } from "@app/pages/BudgetCreation2/TableFooter";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { TableSidebar } from "@app/pages/BudgetCreation2/TableSidebar";
import { useBudgetMatrix } from "@model/budgets/matrix/hooks";

const { Content } = Layout;

const StyledOuterTableContainer = styled.div`
  width: 100%;
  border: 1px solid var(--component-border);
  border-radius: var(--size-2);
  font-size: var(--font-size-footnote);
`;

const StyledInnerTableContainer = styled.section`
  overflow: auto;
  height: calc(100vh - 315px);

  table {
    width: 100%;

    td {
      box-shadow: inset -1px -1px 0 0 var(--component-border);
    }

    & thead {
      & th.activities-header {
        position: sticky;
        top: 0;
        left: 0%;
        z-index: calc(var(--depth-10) * 3);
        width: 400px;
      }

      & th.visit-header {
        box-shadow: inset -1px -1px 0 0 var(--component-border);
      }

      & th {
        background: var(--component-background-subtle);
        text-align: center;
        min-width: var(--table-column-width-default);
        padding: var(--size-3);
        box-shadow: inset -1px -1px 0 0 var(--component-border);
        font-weight: var(--font-weight-medium);
      }
    }

    tbody {
      z-index: var(--depth-1);

      .activity-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        transition: var(--duration-2);
        background: var(--component-background);
        width: 400px;

        &:hover,
        &.ant-popover-open {
          background: var(--component-hover-background);
        }
      }

      td {
        text-align: center;
        padding: var(--size-4);
      }
    }

    .totals-block {
      box-shadow: inset 1px 0 0 0 transparent;
      background: var(--component-background);
      vertical-align: bottom;

      .cost-charge-container {
        article {
          display: flex;
          justify-content: space-between;
          background: var(--green-1);
          border: 1px solid var(--green-5);
          border-radius: var(--size-2);
          margin-bottom: var(--size-3);
          padding: var(--size-4);
          text-align: left;

          .ant-typography {
            font-size: var(--font-size-body);
            color: var(--green-12);
          }

          .total {
            font-size: var(--font-size-body);
            font-weight: var(--font-weight-medium);
          }
        }
      }
    }

    tfoot {
      td {
        background: var(--component-background-subtle);
        padding: var(--size-4);
        box-shadow: inset -1px 0 0 0 var(--component-border);
      }

      .activity-footer {
        background: var(--component-background-subtle);
        position: sticky;
        left: 0;
        z-index: calc(var(--depth-10) * 2);
      }

      .col-summary-footer {
        vertical-align: bottom;
        box-shadow: inset -1px 1px 0 0 var(--component-border);

        &.override {
          background: var(--green-2);
        }

        .cost-charge-container {
          .ant-row {
            height: var(--size-10);
            margin-bottom: var(--size-2);
          }

          .ant-typography {
            font-size: var(--size-6);
          }
        }
      }
    }
  }

  .invoiced-separately-block {
    background: var(--component-background);
    padding: var(--size-4);
    border: 1px solid var(--component-border);
    font-size: var(--font-size-footnote);

    .title {
      display: flex;
      align-items: center;
      gap: var(--size-3);
      font-size: var(--font-size-footnote);
    }
  }

  .sticky-left {
    position: sticky;
    left: 0;
    top: 0;
    z-index: calc(var(--depth-10) * 2);
    text-align: left;
  }

  .sticky-right {
    position: sticky;
    right: 0;
    top: 0;
    z-index: var(--depth-10);
    text-align: right;
  }

  .sticky-top {
    position: sticky;
    z-index: calc(var(--depth-10) * 2);
    top: 0;
  }

  .sticky-bottom {
    position: sticky;
    z-index: var(--depth-10);
    bottom: 0;
  }

  .text-left {
    text-align: left;
  }

  .activity-item-content {
    display: flex;
    flex-direction: column;
  }

  .activity-instance {
    cursor: pointer;
    width: var(--table-column-width-default);
    min-width: var(--table-column-width-default);
    background: var(--component-background);
  }

  .totals-header {
    background: var(--component-background);
    width: var(--table-column-width-default);
    min-width: var(--table-column-width-default);
    text-align: right;
    padding: var(--size-6);
    font-weight: 500;
  }
`;

function useLoadedTable() {
  const matrixBody = useStore((store) => store.matrixBody)!;

  const tracks = matrixBody.visitCharges.tracks;

  const visitActivitySidebarProps = useStore(
    (store) => store.visitActivitySidebarProps
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const budgetId = params.budgetId!;
  const budgetConfigVersionId = params.budgetConfigVersionId!;

  const [visitFilter, setVisitFilter] = useState("");
  const [activityFilter, setActivityFilter] = useState("");

  const [selectedTrack, setSelectedTrack] = useState(
    searchParams.get("selected-track") ??
      (tracks && tracks.length > 0 ? tracks[0].id : "")
  );
  const currentTrack = tracks.find((t) => t.id === selectedTrack);
  const visits = visitFilter
    ? (currentTrack?.visits || []).filter(
        (v) => v.name.toLocaleLowerCase().indexOf(visitFilter) > -1
      )
    : currentTrack?.visits || [];
  const activities = activityFilter
    ? (currentTrack?.activities || []).filter(
        (a) => a.name.toLocaleLowerCase().indexOf(activityFilter) > -1
      )
    : currentTrack?.activities || [];
  const visitActivities = currentTrack?.visitActivities || [];

  const onSelectNewActiveTrack = (value: string) => {
    setSearchParams({ "selected-track": value });
    setSelectedTrack(value);
  };

  const options = tracks.map((t) => ({
    value: t.id,
    label: t.name,
  }));

  const unsortedBudgetTrackOptions = [
    {
      label: "Visit Groups",
      options,
    },
  ];

  const budgetTrackOptions = unsortedBudgetTrackOptions.map((track) => ({
    ...track,
    options: track.options.filter(
      (option) => option.label !== "Unscheduled Visit"
    ),
  }));

  const hasUnscheduledVisitTemplate = unsortedBudgetTrackOptions
    .flatMap((track) => track.options)
    .some((option) => option.label === "Unscheduled Visit");

  const handleVisitFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVisitFilter(e.target.value.trim().toLocaleLowerCase());
  };

  const handleActivityFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setActivityFilter(e.target.value.trim().toLocaleLowerCase());
  };

  return {
    visitActivitySidebarProps,
    budgetId,
    budgetConfigVersionId,
    visits,
    activities,
    visitActivities,
    onSelectNewActiveTrack,
    budgetTrackOptions,
    handleVisitFilterChange,
    handleActivityFilterChange,
    selectedTrack,
    tracks,
    activityFilter,
    visitFilter,
    hasUnscheduledVisitTemplate,
  };
}

function LoadedTable({
  Actions,
  Header,
  Body,
  Footer,
  siteTrialId,
}: TableRootProps & { siteTrialId: string }) {
  const {
    visitActivitySidebarProps,
    budgetId,
    budgetConfigVersionId,
    visits,
    activities,
    visitActivities,
    onSelectNewActiveTrack,
    budgetTrackOptions,
    handleVisitFilterChange,
    handleActivityFilterChange,
    selectedTrack,
    tracks,
    activityFilter,
    visitFilter,
    hasUnscheduledVisitTemplate,
  } = useLoadedTable();

  const [, updateMatrix] = useBudgetMatrix({
    budgetId,
    budgetConfigVersionId,
    siteTrialId,
    track: selectedTrack,
  });

  useEffect(() => {
    updateMatrix("init");
    // eslint-disable-next-line
  }, []);

  return (
    <SelectedTrackContext.Provider value={selectedTrack}>
      <Layout>
        <Content>
          <StyledOuterTableContainer>
            <StyledInnerTableContainer>
              <Actions
                onSelectNewActiveTrack={onSelectNewActiveTrack}
                budgetTrackOptions={budgetTrackOptions}
                selectedTrack={selectedTrack}
                tracks={tracks}
                handleVisitFilterChange={handleVisitFilterChange}
                hasUnscheduledVisitTemplate={hasUnscheduledVisitTemplate}
              />
              <table data-testid="budget-matrix">
                <Header
                  budgetId={budgetId}
                  budgetConfigVersionId={budgetConfigVersionId}
                  selectedTrack={selectedTrack}
                  handleActivityFilterChange={handleActivityFilterChange}
                  visitFilter={visitFilter}
                />
                <Body
                  activityFilter={activityFilter}
                  visitFilter={visitFilter}
                  budgetId={budgetId}
                  budgetConfigVersionId={budgetConfigVersionId}
                  siteTrialId={siteTrialId}
                  visits={visits}
                  activities={activities}
                  visitActivities={visitActivities}
                  selectedTrack={selectedTrack}
                />
                <Footer
                  budgetId={budgetId}
                  budgetConfigVersionId={budgetConfigVersionId}
                  siteTrialId={siteTrialId}
                  selectedTrack={selectedTrack}
                  visitFilter={visitFilter}
                />
              </table>
            </StyledInnerTableContainer>
          </StyledOuterTableContainer>
        </Content>
        {visitActivitySidebarProps ? (
          <TableSidebar
            budgetId={budgetId}
            budgetConfigVersionId={budgetConfigVersionId}
            siteTrialId={siteTrialId}
            selectedTrack={selectedTrack}
          />
        ) : null}
      </Layout>
    </SelectedTrackContext.Provider>
  );
}

interface TableRootProps {
  Actions: React.FC<TableActionsProps>;
  Header: React.FC<TableHeaderProps>;
  Body: React.FC<TableBodyProps>;
  Footer: React.FC<TableFooterProps>;
}

export const TableRoot = (props: TableRootProps) => {
  const selectedTrack = useSelectedTrack();
  const {
    pageBudgetId,
    isLoading,
    isError,
    protocol,
    budget,
    pageBudgetConfigVersionId,
  } = useBudget({ track: selectedTrack });

  const populateMatrix = useStore((store) => store.populateMatrix);

  if (!pageBudgetId) {
    return null;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return null;
  }

  if (!budget) {
    return null;
  }

  populateMatrix(protocol, budget, pageBudgetConfigVersionId!);

  return <LoadedTable {...props} siteTrialId={budget.trial.stId} />;
};
