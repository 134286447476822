import {
  GraphQLMoney,
  max,
  minus,
  money,
  Money,
  plus,
  toNumber,
} from "@lib/currency";
import { flow, pick } from "lodash";
import {
  formatDate,
  localDateToUTCDateString,
  utcDateStringToLocalDate,
} from "@lib/date";
import { addDays, format, isAfter, isBefore, isWithinInterval } from "date-fns";
import { getVisitsFromSchedule } from "@app/model/protocols";

type ProtocolVersionLookupInfo = {
  visits: Record<string, { name: string }>;
  activities: Record<string, { name: string }>;
  versionInfo: { name: string };
};

type ProtocolVersionLookup = Record<string, ProtocolVersionLookupInfo>;

function additionalChargeChargeTypeDisplayField(
  chargeType: IFinancials2__ChargeType | null
) {
  switch (chargeType) {
    case "ADDITIONAL_CHARGE":
      return "Additional Charge";
    case "VISIT_ACTIVITY_CHARGE":
      return "Activity";
    default:
      return "";
  }
}

function getProtocolVisit(sourceEvent: any) {
  const protocolVisitId =
    sourceEvent.protocolVisit?.crossVersionId ??
    sourceEvent.protocolVisitCrossVersionId;

  if (protocolVisitId) {
    const lookup =
      sourceEvent.protocolVersion ?? sourceEvent.allProtocolVersions;
    const visits = getVisitsFromSchedule(lookup);
    return visits?.find((v) => v.crossVersionId === protocolVisitId);
  } else {
    return null;
  }
}

function getVisitName(sourceEvent: any) {
  let protocolVisit;

  if (sourceEvent.visitName) {
    return sourceEvent.visitName;
  } else if ((protocolVisit = getProtocolVisit(sourceEvent))) {
    return protocolVisit.name;
  } else {
    return sourceEvent.source;
  }
}

function getVisitActivityName(sourceEvent: SourceEventWithExternalMetadata) {
  const lookup = sourceEvent.protocolVersion ?? sourceEvent.allProtocolVersions;

  return (
    lookup?.activities[
      sourceEvent.protocolActivity?.crossVersionId ||
        // @ts-ignore
        sourceEvent.protocolActivityCrossVersionId
    ]?.name ?? sourceEvent.source
  );
}

export function addDescriptionAndVisitName(sourceEvents: any[]) {
  return sourceEvents.map((sourceEvent) => {
    const visitName = getVisitName(sourceEvent);

    switch (sourceEvent.source) {
      case "PROTOCOL_VISIT": {
        return {
          ...sourceEvent,
          visitName,
          description: visitName,
        };
      }
      case "PROTOCOL_VISIT_ACTIVITY": {
        const description = getVisitActivityName(sourceEvent);

        return {
          ...sourceEvent,
          visitName,
          description,
        };
      }
      case "AD_HOC_FROM_SEND_TO_RECEIVABLES": {
        return {
          ...sourceEvent,
          visitName: null,
        };
      }
      case "AD_HOC_FROM_NEW_CHARGE_FORM": {
        const { chargeType } = sourceEvent;
        return {
          ...sourceEvent,
          visitName: chargeType === "VISIT_ACTIVITY_CHARGE" ? visitName : null,
        };
      }
      default:
        return sourceEvent;
    }
  });
}

function getCompletedDate(sourceEvent: SourceEvent) {
  return (
    sourceEvent.visitOutcomeDate ??
    sourceEvent.activityCompletedAt ??
    sourceEvent.adHocCompletedAt
  );
}

function getProtocolVersionName(sourceEvent: SourceEventWithExternalMetadata) {
  return sourceEvent?.protocolVersion.versionInfo?.name;
}

function addDisplayFields(sourceEvents: SourceEventWithExternalMetadata[]) {
  return sourceEvents.map((sourceEvent) => {
    const completedDate = getCompletedDate(sourceEvent);
    const protocolVersionName = getProtocolVersionName(sourceEvent);

    switch (sourceEvent.source) {
      case "PROTOCOL_VISIT": {
        const { dueDate, protocolVersion } = sourceEvent;

        return {
          ...sourceEvent,
          rawCompletedDate: completedDate,
          completedDate: completedDate && formatDate(completedDate),
          rawDueDate: dueDate,
          dueDate: dueDate && formatDate(dueDate),
          protocolVersionName,
          protocolVersion: protocolVersion?.versionInfo,
          chargeType: "Visit",
          source: "budget-visit",
        };
      }
      case "PROTOCOL_VISIT_ACTIVITY": {
        const { dueDate, protocolVersion } = sourceEvent;

        return {
          ...sourceEvent,
          rawCompletedDate: completedDate,
          completedDate: completedDate && formatDate(completedDate),
          rawDueDate: dueDate,
          dueDate: dueDate && formatDate(dueDate),
          protocolVersionName,
          protocolVersion: protocolVersion.versionInfo,
          chargeType: "Activity",
          source: "budget-visit-activity",
        };
      }
      case "AD_HOC_FROM_SEND_TO_RECEIVABLES": {
        const { dueDate, protocolVersion, chargeType } = sourceEvent;

        return {
          ...sourceEvent,
          rawCompletedDate: completedDate,
          completedDate: completedDate && formatDate(completedDate),
          rawDueDate: dueDate,
          dueDate: dueDate && formatDate(dueDate),
          protocolVersionName,
          protocolVersion: protocolVersion.versionInfo,
          chargeType: additionalChargeChargeTypeDisplayField(chargeType),
          source: "sendToReceivables",
        };
      }
      case "AD_HOC_FROM_NEW_CHARGE_FORM": {
        const { dueDate, protocolVersion, chargeType } = sourceEvent;

        return {
          ...sourceEvent,
          rawCompletedDate: completedDate,
          completedDate: completedDate && formatDate(completedDate),
          rawDueDate: dueDate,
          dueDate: dueDate && formatDate(dueDate),
          protocolVersionName,
          protocolVersion: protocolVersion.versionInfo,
          chargeType: additionalChargeChargeTypeDisplayField(chargeType),
          source: "adHocForm",
        };
      }
      default:
        return sourceEvent;
    }
  });
}

function pickBudgetedEvents(sourceEvents: any[]) {
  return sourceEvents.map((sourceEvent) =>
    pick(sourceEvent, [
      "id",
      "protocol",
      "protocolVisit",
      "protocolActivity",
      "siteTrial",
      "charge",
      "category",
      "invoiceable",
      "category",
      "siteTrialPatient",
      "description",
      "type",
      "dueDate",
      "rawDueDate",
      "rawCompletedDate",
      "completedDate",
      "visitName",
      "protocolVersionName",
      "externalStatus",
      "protocolVersion",
      "chargeType",
      "source",
      "sponsor",
      "subjectId",
      "remainingAmount",
      "allocatedAmount",
      "paidStatus",
      "adHocReceivableId",
      "patientProtocolVisitId",
      "unscheduledPatientProtocolVisitId",
      "holdback",
      "overhead",
      "totalPayableAmount",
      "lineItemId",
    ])
  );
}

const generateARProtocolLookupTable = (
  protocols: ProtocolForSiteTrial[]
): ProtocolVersionLookup => {
  const allVersions = protocols.flatMap((p) => p!.versions);
  return allVersions.reduce<ProtocolVersionLookup>((acc, version) => {
    const allVisits = version.schedules.flatMap((s: any) =>
      getVisitsFromSchedule(s)
    );
    const allActivities = version.schedules.flatMap(
      (s: any) => s.siteActivities
    );

    const record = acc[version.id] ?? {
      visits: {},
      activities: {},
      versionInfo: {
        id: version.id,
        name: version.name,
      },
    };

    const updated = {
      ...record,
      visits: allVisits.reduce((acc, v) => {
        acc[v.crossVersionId!] = { name: v.name! };
        return acc;
      }, record.visits),
      activities: allActivities.reduce((acc, a) => {
        acc[a?.crossVersionId!] = { name: a?.name };
        return acc;
      }, record.activities),
    };

    acc[version.id] = updated;
    return acc;
  }, {});
};

type SourceEventWithExternalMetadata = SourceEvent & {
  allProtocolVersions?: Pick<
    ProtocolVersionLookupInfo,
    "visits" | "activities"
  >;
  protocolVersion: ProtocolVersionLookupInfo;
};

function addProtocolSingleVersionLookup(
  sourceEvents: SourceEvent[],
  protocols: any
): SourceEventWithExternalMetadata[] {
  const protocolVersionLookup = generateARProtocolLookupTable(protocols);

  return sourceEvents.map((sourceEvent: any) => {
    let protocolVersion = null;

    if (sourceEvent.protocolVersion?.id) {
      protocolVersion = protocolVersionLookup[sourceEvent.protocolVersion?.id];
    }

    if (sourceEvent.protocolVersionId) {
      protocolVersion = protocolVersionLookup[sourceEvent.protocolVersionId];
    }

    return {
      ...sourceEvent,
      protocolVersion,
    };
  });
}

interface ARLineItem {
  adHocReceivableId: string;
  category: string | null;
  charge: { amount: string; currencyCode: "USD" };
  chargeType: string;
  completedDate: string;
  description: string | null;
  externalStatus: IFinancials2__AccountsReceivableLineItemExternalStatus;
  holdback: IFinancials2__Holdback | null;
  id: string;
  lineItemId: string;
  invoiceable: boolean;
  patientProtocolVisitId: string | null;
  unscheduledPatientProtocolVisitId: string | null;
  protocol: any;
  protocolActivity: any;
  protocolVersion: { id: string; name: string };
  protocolVersionName: string;
  protocolVisit: any;
  rawCompletedDate: string;
  siteTrial: { id: string; name: string };
  source: string;
  sponsor: { id: string | null; name: string | null };
  subjectId: string | null;
  visitName: string | null;
  paidStatus: IFinancials2__PaidStatus;
  remainingAmount: Money;
  dueDate: string | null;
}

export type SourceEvent =
  IAccountsReceivableSourceEventsQuery["Financials2__accountsReceivable"]["summary3"]["sourceEvents"][number];

export function lineItems(
  sourceEvents: SourceEvent[],
  protocols: any
): ARLineItem[] {
  const transform = flow(
    (x) => addProtocolSingleVersionLookup(x, protocols),
    (x) => addDescriptionAndVisitName(x),
    addDisplayFields,
    pickBudgetedEvents
  );

  return transform(sourceEvents);
}

export function defaultDueDate(completedDate: string | null, budget: any) {
  if (!completedDate) return null;

  const { paymentTermDays } = budget;
  const newDate = addDays(
    utcDateStringToLocalDate(completedDate),
    paymentTermDays
  );

  return localDateToUTCDateString(newDate);
}

export interface LineItem {
  id: string;
  lineItemId: string;
  invoiceable: any;
  subjectId: string | null;
  description: string | null;
  type: string;
  visitName: string | null;
  protocolVersion: { name: string; id: string };
  protocolVersionName: string;
  externalStatus: IFinancials2__AccountsReceivableLineItemExternalStatus;
  chargeType: string;
  source: string;
  completedDate: string;
  rawCompletedDate: string;
  dueDate: string | null;
  rawDueDate: string;
  protocol: { id: string };
  paidStatus: IFinancials2__PaidStatus;
  remainingAmount: Money | GraphQLMoney;
  allocatedAmount: Money | GraphQLMoney;
  protocolVisit: { crossVersionId: string };
  protocolActivity: { crossVersionId: string };
  siteTrial: { id: string; name: string };
  charge: Money | GraphQLMoney;
  category: string | null;
  holdback:
    | (Omit<IFinancials2__Holdback, "money"> & {
        money: Money | GraphQLMoney | null;
      })
    | null;
  overhead: IFinancials2__Overhead | null;
  totalPayableAmount: Money | GraphQLMoney | null;
  sponsor: { id: string | null; name: string | null } | null;
}

export function isGenerateInvoiceAllowed(
  lineItems: Pick<LineItem, "externalStatus" | "paidStatus" | "holdback">[]
): boolean {
  return Boolean(
    lineItems.length &&
      !lineItems.some(
        (lineItem) =>
          lineItem.externalStatus === "VOID" ||
          lineItem.externalStatus === "PAID" ||
          lineItem.paidStatus === "PARTIALLY_PAID" ||
          lineItem.paidStatus === "FULLY_PAID"
      )
  );
}

export function isBulkPaymentActionAllowed(
  lineItems: Pick<LineItem, "externalStatus" | "paidStatus">[]
): boolean {
  return Boolean(
    lineItems.length &&
      !lineItems.some(
        (lineItem) =>
          lineItem.externalStatus === "VOID" ||
          lineItem.externalStatus === "PAID" ||
          (lineItem.externalStatus !== "HOLDBACK" &&
            lineItem.paidStatus === "PARTIALLY_PAID") ||
          lineItem.paidStatus === "FULLY_PAID"
      )
  );
}

export const isMarkPaidActionAllowed = isBulkPaymentActionAllowed;
export const isMarkVoidActionAllowed = isBulkPaymentActionAllowed;

export function isClearHoldbacksActionAllowed(
  lineItems: Pick<LineItem, "externalStatus" | "paidStatus" | "holdback">[]
): boolean {
  return (
    isBulkPaymentActionAllowed(lineItems) &&
    Boolean(
      lineItems.length &&
        lineItems.filter(
          (i) => i.holdback && i.holdback.enabled && !i.holdback.cleared
        ).length === lineItems.length
    )
  );
}

export function hasMoreActions(
  lineItems: Pick<LineItem, "externalStatus" | "paidStatus" | "holdback">[]
): boolean {
  return (
    isMarkPaidActionAllowed(lineItems) ||
    isMarkVoidActionAllowed(lineItems) ||
    isClearHoldbacksActionAllowed(lineItems)
  );
}

export function hasNoActions(
  lineItems: Pick<LineItem, "externalStatus" | "paidStatus" | "holdback">[]
): boolean {
  return !isGenerateInvoiceAllowed(lineItems) && !hasMoreActions(lineItems);
}

export function isReopenVisible(lineItem: LineItem) {
  return lineItem.externalStatus === "PAID";
}

export type TotalsLineItem = Pick<
  LineItem,
  "externalStatus" | "paidStatus" | "remainingAmount" | "charge" | "holdback"
>;

function identity<T>(x: T): T {
  return x;
}

function overallTotal(lineItems: TotalsLineItem[]): Money {
  return lineItems
    .filter(
      (lineItem) =>
        lineItem.externalStatus === "OPEN" ||
        lineItem.externalStatus === "PAST_DUE" ||
        lineItem.externalStatus === "HOLDBACK"
    )
    .reduce(
      (acc, lineItem) =>
        flow(
          plus(
            lineItem.paidStatus === "PARTIALLY_PAID"
              ? lineItem.remainingAmount
              : lineItem.charge
          ),
          lineItem.holdback?.cleared
            ? identity
            : minus(lineItem.holdback?.money ?? 0),
          max(0)
        )(acc),
      money(0)
    );
}

function overallRecordCount(lineItems: TotalsLineItem[]): number {
  return lineItems.filter(
    (lineItem) =>
      lineItem.externalStatus === "OPEN" ||
      lineItem.externalStatus === "PAST_DUE" ||
      lineItem.externalStatus === "HOLDBACK"
  ).length;
}

function overallPastDueTotal(lineItems: TotalsLineItem[]): Money {
  return lineItems
    .filter((lineItem) => lineItem.externalStatus === "PAST_DUE")
    .reduce(
      (number, lineItem) =>
        plus(
          number,
          lineItem.paidStatus === "PARTIALLY_PAID"
            ? lineItem.remainingAmount
            : lineItem.charge
        ),
      money(0)
    );
}

function holdbackTotal(lineItems: TotalsLineItem[]): Money {
  return lineItems
    .filter(
      (lineItem) =>
        lineItem.externalStatus === "OPEN" ||
        lineItem.externalStatus === "PAST_DUE" ||
        lineItem.externalStatus === "HOLDBACK"
    )
    .reduce((number, { holdback }) => {
      if (holdback && holdback.enabled && !holdback.cleared && holdback.money) {
        return plus(number, holdback.money);
      }

      return number;
    }, money(0));
}

export interface Totals {
  overallTotal: Money;
  overallRecordCount: number;
  overallPastDueTotal: Money;
  holdbackTotal: Money;
}

/**
 * Returns totals calculated from a list of A/R Line Items.
 */
export function totals(lineItems: TotalsLineItem[]): Totals {
  return {
    overallTotal: overallTotal(lineItems),
    overallRecordCount: overallRecordCount(lineItems),
    overallPastDueTotal: overallPastDueTotal(lineItems),
    holdbackTotal: holdbackTotal(lineItems),
  };
}

export type FilterableLineItem = Pick<
  LineItem,
  | "id"
  | "lineItemId"
  | "paidStatus"
  | "holdback"
  | "completedDate"
  | "dueDate"
  | "siteTrial"
  | "visitName"
  | "invoiceable"
  | "externalStatus"
  | "subjectId"
  | "description"
  | "sponsor"
  | "source"
>;

type ActiveFilter = Partial<Record<keyof FilterableLineItem, unknown>>;

export const isVisible = (
  lineItem: FilterableLineItem,
  activeFilter?: ActiveFilter
) => {
  if (!activeFilter) return true;

  const keys = Object.entries(activeFilter)
    .filter(([_key, value]) => value)
    .map((entry) => entry[0]);
  const values = Object.entries(activeFilter)
    .filter(([_key, value]) => value)
    .map((entry) => entry[1]);

  if (keys.length > 0) {
    return keys.every((key, index) => {
      if (key === "completedDate" || key === "dueDate") {
        const [start, end] = values[index] as any;

        const startDateISOString =
          start &&
          new Date(format(new Date(start.toISOString()), "dd-MMM-yyy"));
        const endDateISOString =
          end && new Date(format(new Date(end.toISOString()), "dd-MMM-yyy"));

        const completedDate = new Date(lineItem[key]!);

        if (startDateISOString && endDateISOString) {
          return isWithinInterval(completedDate, {
            start: startDateISOString,
            end: endDateISOString,
          });
        } else if (start) {
          return isAfter(completedDate, startDateISOString);
        } else if (end) {
          return isBefore(completedDate, endDateISOString);
        }
      }
      if (key === "siteTrial") {
        return (values[index] as string[]).includes(lineItem[key].name);
      }
      if (key === "visitName") {
        return (values[index] as string[]).includes(lineItem[key] ?? "");
      }
      if (key === "invoiceable") {
        const lineItemInvoiceable = lineItem[key] ? "Yes" : "No";

        return (values[index] as string[]).includes(lineItemInvoiceable);
      }

      if (key === "externalStatus") {
        return (values[index] as string[]).includes(lineItem[key] ?? "");
      }

      if (key === "subjectId") {
        return (values[index] as string[]).includes(lineItem[key] || "N/A");
      }

      return false;
    });
  }

  return true;
};

export function isMatch(
  lineItem: FilterableLineItem,
  searchTerm?: string
): boolean {
  if (!searchTerm) return true;
  if (!lineItem.description) return false;

  return lineItem.description
    .toLowerCase()
    .includes(searchTerm.trim().toLowerCase());
}

export function filteredLineItems<T extends FilterableLineItem>(
  lineItems: T[],
  {
    activeFilter,
    searchTerm,
  }: { activeFilter?: ActiveFilter; searchTerm?: string }
): T[] {
  return lineItems
    .filter((lineItem) => isVisible(lineItem, activeFilter))
    .filter((lineItem) => isMatch(lineItem, searchTerm));
}

export const selectedStatuses = (
  lineItems: FilterableLineItem[],
  selectedRowKeys: React.Key[]
): Pick<LineItem, "paidStatus" | "externalStatus" | "holdback">[] => {
  const filtered = lineItems.filter((lineItem) =>
    selectedRowKeys.includes(lineItem.id)
  );

  const sponsorIds = Array.from(
    new Set(filtered.map((filter) => filter.sponsor?.id))
  ).filter((sponsorId) => sponsorId); // site specific activities have no sponsor

  if (sponsorIds.length > 0) {
    const firstSponsor = sponsorIds[0]; // for now.

    const filteredToFirstSponsorLineItems = filtered
      .filter((filter) => filter.sponsor?.id === firstSponsor)
      .filter(
        (lineItem) =>
          lineItem.source === "budget-visit" ||
          lineItem.source === "budget-visit-activity" ||
          lineItem.source === "sendToReceivables" ||
          lineItem.source === "adHocForm"
      );

    return [
      ...new Set(
        filteredToFirstSponsorLineItems.map((lineItem) => ({
          externalStatus: lineItem.externalStatus,
          paidStatus: lineItem.paidStatus,
          holdback: lineItem.holdback,
        }))
      ),
    ];
  } else {
    // when budget exists with zero sponsor don't filter on any sponsor
    const filteredToPertinentLineItems = filtered.filter(
      (lineItem) =>
        lineItem.source === "budget-visit" ||
        lineItem.source === "budget-visit-activity" ||
        lineItem.source === "sendToReceivables" ||
        lineItem.source === "adHocForm"
    );

    return [
      ...new Set(
        filteredToPertinentLineItems.map((lineItem) => ({
          externalStatus: lineItem.externalStatus,
          paidStatus: lineItem.paidStatus,
          holdback: lineItem.holdback,
        }))
      ),
    ];
  }
};

export const transformForARActions = (
  lineItems: any[],
  selectedRowKeys: React.Key[]
): {
  transformedLineItems: {
    adHocReceivableId: string | null;
    patientProtocolVisitId: string | null;
    unscheduledPatientProtocolVisitId: string | null;
    protocolActivityCrossVersionId: string | null;
  }[];
  tableIds: string[];
  sponsorId: string | null;
  siteTrialId: string;
} => {
  const filtered = lineItems.filter((lineItem) =>
    selectedRowKeys.includes(lineItem.id)
  );

  const sponsorIds = Array.from(
    new Set(filtered.map((filter) => filter.sponsor?.id))
  ).filter((sponsorId) => sponsorId);

  const siteTrialIds = Array.from(
    new Set(filtered.map((filter) => filter.siteTrial.id))
  );

  if (sponsorIds.length > 0) {
    const firstSponsor = sponsorIds[0]; // for now.
    const firstSiteTrial = siteTrialIds[0];

    const filteredToFirstSponsorLineItemIds = filtered
      .filter((filter) => filter.sponsor.id === firstSponsor)
      .map((lineItem) => lineItem.id);

    const filteredToFirstSponsorLineItems = filtered
      .filter((filter) => filter.sponsor.id === firstSponsor)
      .filter(
        (lineItem) =>
          lineItem.source !== "budget-visit" ||
          // @ts-ignore
          lineItem.source !== "budget-visit-activity" ||
          lineItem.source !== "sendToReceivables" ||
          lineItem.source !== "adHocForm"
      )
      .map((lineItem) => {
        return {
          adHocReceivableId: lineItem.adHocReceivableId,
          patientProtocolVisitId: lineItem.patientProtocolVisitId,
          unscheduledPatientProtocolVisitId:
            lineItem.unscheduledPatientProtocolVisitId,
          protocolActivityCrossVersionId:
            lineItem.protocolActivity?.crossVersionId ?? null,
        };
      });

    return {
      tableIds: filteredToFirstSponsorLineItemIds,
      transformedLineItems: filteredToFirstSponsorLineItems,
      sponsorId: firstSponsor,
      siteTrialId: firstSiteTrial,
    };
  } else {
    const firstSiteTrial = siteTrialIds[0];

    const filteredToFirstSponsorLineItemIds = filtered.map(
      (lineItem) => lineItem.id
    );

    const filteredToFirstSponsorLineItems = filtered
      .filter(
        (lineItem) =>
          lineItem.source !== "budget-visit" ||
          // @ts-ignore
          lineItem.source !== "budget-visit-activity" ||
          lineItem.source !== "sendToReceivables" ||
          lineItem.source !== "adHocForm"
      )
      .map((lineItem) => {
        return {
          adHocReceivableId: lineItem.adHocReceivableId,
          patientProtocolVisitId: lineItem.patientProtocolVisitId,
          unscheduledPatientProtocolVisitId:
            lineItem.unscheduledPatientProtocolVisitId,
          protocolActivityCrossVersionId:
            lineItem.protocolActivity?.crossVersionId ?? null,
        };
      });

    return {
      tableIds: filteredToFirstSponsorLineItemIds,
      transformedLineItems: filteredToFirstSponsorLineItems,
      sponsorId: null,
      siteTrialId: firstSiteTrial,
    };
  }
};

export const transformForNewPayment = (
  lineItems: any[],
  selectedRowKeys: React.Key[]
): {
  selectedSiteTrialId: string;
  rowKeys: string[];
} => {
  const { siteTrialId, transformedLineItems } = transformForARActions(
    lineItems,
    selectedRowKeys
  );

  const invoiceId = null;

  // source of rowKey creation in usePayments
  const invoiceTableIds = transformedLineItems.map((lineItem) => {
    const adHocReceivableId = lineItem.adHocReceivableId || null;
    const patientProtocolVisitId = lineItem.patientProtocolVisitId || null;
    const unscheduledPatientProtocolVisitId =
      lineItem.unscheduledPatientProtocolVisitId || null;
    const protocolActivityCrossVersionId =
      lineItem.protocolActivityCrossVersionId || null;

    return `${invoiceId}|${adHocReceivableId}|${patientProtocolVisitId}|${unscheduledPatientProtocolVisitId}|${protocolActivityCrossVersionId}`;
  });

  return {
    selectedSiteTrialId: siteTrialId,
    rowKeys: invoiceTableIds,
  };
};

export interface PaidAmountProps {
  paidStatus: IFinancials2__PaidStatus | null;
  allocatedAmount?: Money | null;
  totalAmount?: Money;
  totalPayableAmount?: Money | null;
  remainingTotal?: Money;
  externalStatus?: IFinancials2__AccountsReceivableLineItemExternalStatus;
}

export function paidAmount2({
  paidStatus,
  totalAmount,
  totalPayableAmount,
  remainingTotal,
  allocatedAmount,
  externalStatus,
}: PaidAmountProps): Money[] | null {
  if (paidStatus === "UNPAID" && totalAmount) {
    return [totalPayableAmount ?? totalAmount];
  } else if (
    (paidStatus === "PARTIALLY_PAID" &&
      remainingTotal &&
      toNumber(remainingTotal) > 0) ||
    externalStatus === "HOLDBACK"
  ) {
    return [allocatedAmount!, totalPayableAmount ?? totalAmount!];
  } else if (paidStatus && totalAmount && allocatedAmount && remainingTotal) {
    return [totalAmount];
  } else {
    return null;
  }
}
