import { useUserSiteTrialsQuery } from "@app/service/generated";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Radio,
  Checkbox,
  FormInstance,
} from "@reifyhealth/picasso-pkg";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { additionalChargesCategories } from "@model/budgets";
import moment from "moment";
import { utcDateStringToLocalDate } from "@lib/date";
import { format, isAfter } from "date-fns";
import sortBy from "lodash/sortBy";
import { Money, toString } from "@lib/currency";
import MoneyInput from "@components/inputs/MoneyInput";
import { getVisitsFromSchedule } from "@app/model/protocols";

interface AdHocReceivablesFormShape {
  description: string;
  charge: string;
  siteTrialId: string;
  protocolVersionId: string;
  completedAt: string;
  dueDate: string;
  chargeType: string;
  category: string;
  subjectId: string;
  protocolVisitCrossVersionId: string;
  invoiceable: boolean;
}

const { TextArea } = Input;

interface AdHocReceivablesFormProps<T> {
  form: FormInstance<T>;
  existingAdHocReceivable: {
    id: string;
    invoiceable: any;
    subjectId: string;
    description: string;
    visitName: string;
    protocolVersion: { id: string; name: string };
    protocolVersionName: string;
    chargeType: string;
    dueDate: string;
    rawDueDate: string;
    completedDate: string;
    rawCompletedDate: string;
    protocol: { id: string };
    protocolVisit: { crossVersionId: string };
    protocolActivity: { crossVersionId: string };
    siteTrial: { id: string; name: string };
    charge: Money;
    category: string | null;
  } | null;
}

export const AdHocReceivablesForm = ({
  form,
  existingAdHocReceivable,
}: AdHocReceivablesFormProps<AdHocReceivablesFormShape>) => {
  const [siteTrialId, setSiteTrialId] = useState<string | undefined>(undefined);
  const [protocolVersionId, setProtocolVersionId] = useState<
    string | undefined
  >(undefined);
  const [isInvoiceable, setIsInvoiceable] = useState<boolean>(true);
  const [showOtherFormItems, setShowFormItems] = useState<boolean>(false);

  useEffect(() => {
    form.resetFields();

    if (existingAdHocReceivable) {
      setSiteTrialId(existingAdHocReceivable.siteTrial.id);
      setProtocolVersionId(existingAdHocReceivable.protocolVersion.id);
      setIsInvoiceable(Boolean(existingAdHocReceivable.invoiceable));
      setShowFormItems(existingAdHocReceivable.chargeType === "Activity");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const params = useParams();
  const {
    data: userSiteTrialsWithBudget,
    isLoading,
    isFetching,
  } = useUserSiteTrialsQuery(
    {
      filter: { siteId: params.siteId!, hasBudget: true },
    },
    {
      queryKey: "userSiteTrialWithBudgetsOnly",
    }
  );

  const siteTrialOptions =
    sortBy(
      userSiteTrialsWithBudget?.Financials2__userSiteTrials.edges
        .filter((trial) => trial.node.protocol !== null)
        .map((edge) => ({
          value: edge.node.id,
          label: edge.node.name,
        })),
      "label"
    ) || [];

  const siteTrialProtocolVersionOptions =
    sortBy(
      userSiteTrialsWithBudget?.Financials2__userSiteTrials.edges
        .filter((edge) => edge.node.id === siteTrialId)
        .filter((edge) => edge.node.protocol)
        .flatMap((edge) => edge.node.protocol?.versions)
        .filter((version) => version?.status === "PUBLISHED")
        .map((protocol) => ({
          value: protocol?.id ?? "",
          label: protocol?.name ?? "",
        }))
        .filter((entry) => entry.label || entry.value),
      "label"
    ) || [];

  const visitForProtocolVersionOptions =
    sortBy(
      userSiteTrialsWithBudget?.Financials2__userSiteTrials.edges
        .filter((edge) => edge.node.id === siteTrialId)
        .filter((edge) => edge.node.protocol)
        .flatMap((edge) => edge.node.protocol?.versions)
        .filter((version) => version?.status === "PUBLISHED")
        .filter((version) => version?.id === protocolVersionId)
        .flatMap((protocol) => getVisitsFromSchedule(protocol?.schedules[0]))
        .map((visits) => ({
          value: visits?.crossVersionId ?? "",
          label: visits?.name ?? "",
        }))
        .filter((entry) => entry.label || entry.value),
      "label"
    ) || [];

  const handleFormChange = (
    changed: Partial<AdHocReceivablesFormShape>,
    all: Partial<AdHocReceivablesFormShape>
  ) => {
    const { siteTrialId, chargeType, protocolVersionId, invoiceable } = all;

    if (changed.chargeType) form.resetFields(["category", "subjectId"]);

    if (changed.siteTrialId) {
      setSiteTrialId(siteTrialId);
      setProtocolVersionId(undefined);
      form.setFieldsValue({
        protocolVersionId: undefined,
        protocolVisitCrossVersionId: undefined,
      });
    } else {
      setShowFormItems(chargeType === "VISIT_ACTIVITY_CHARGE");
      setSiteTrialId(siteTrialId);
      setProtocolVersionId(protocolVersionId);
      setIsInvoiceable(invoiceable!);
    }
  };

  const categoryOptions = additionalChargesCategories.map((category) => ({
    label: category,
    value: category,
  }));

  return (
    <Form
      id="adHocReceivables"
      form={form}
      initialValues={
        existingAdHocReceivable
          ? {
              ...existingAdHocReceivable,
              siteTrialId: existingAdHocReceivable.siteTrial.id,
              protocolVersionId: existingAdHocReceivable.protocolVersion.id,
              chargeType:
                existingAdHocReceivable.chargeType === "Activity"
                  ? "VISIT_ACTIVITY_CHARGE"
                  : "ADDITIONAL_CHARGE",
              description: existingAdHocReceivable.description,
              charge: toString(existingAdHocReceivable.charge),
              completedAt: moment(
                utcDateStringToLocalDate(
                  existingAdHocReceivable?.rawCompletedDate
                )
              ),
              dueDate: moment(
                utcDateStringToLocalDate(existingAdHocReceivable?.rawDueDate)
              ),
              subjectId:
                existingAdHocReceivable.chargeType === "Activity"
                  ? existingAdHocReceivable.subjectId
                  : null,
              protocolVisitCrossVersionId:
                existingAdHocReceivable.protocolVisit?.crossVersionId,
              category: existingAdHocReceivable.category,
              invoiceable: existingAdHocReceivable.invoiceable,
            }
          : { chargeType: "ADDITIONAL_CHARGE", invoiceable: isInvoiceable }
      }
      onValuesChange={handleFormChange}
      layout="vertical"
    >
      <Form.Item
        required
        name="description"
        rules={[
          {
            required: true,
            message: "",
          },
        ]}
        label="Description"
      >
        <TextArea
          style={{ resize: "none" }}
          rows={3}
          placeholder="Describe this charge"
          data-testid="ad-hoc-receivables-form-description-text-area"
        />
      </Form.Item>
      <Form.Item
        label="Charge:"
        rules={[
          {
            required: true,
            message: "",
          },
        ]}
        required
        name="charge"
      >
        <MoneyInput data-testid="visit-charge-input" />
      </Form.Item>
      <Form.Item
        label="Trial Name:"
        name="siteTrialId"
        rules={[
          {
            required: true,
            message: "",
          },
        ]}
        required
      >
        <Select
          data-testid="site-trial-select"
          placeholder="Choose a Trial"
          loading={isFetching || isLoading}
          disabled={isFetching || isLoading}
          options={siteTrialOptions}
        />
      </Form.Item>
      <Form.Item
        label="Protocol Version:"
        name="protocolVersionId"
        dependencies={["siteTrialId"]}
        rules={[
          {
            required: true,
            message: "",
          },
        ]}
        required
      >
        <Select
          data-testid="site-trial-protocol-select"
          placeholder="Choose a version"
          disabled={!siteTrialId}
          options={siteTrialProtocolVersionOptions}
        />
      </Form.Item>
      <Form.Item
        name="completedAt"
        label="Completed Date:"
        rules={[
          {
            required: true,
            message: "",
          },
        ]}
        required
      >
        <DatePicker
          data-testid="effective-dates-datepicker"
          onChange={(date) =>
            form.setFields([{ value: date, name: "completedAt" }])
          }
          style={{ width: "100%" }}
          placeholder="Start Date"
        />
      </Form.Item>
      <Form.Item
        name="dueDate"
        label="Due Date:"
        rules={[
          {
            required: true,
            message: "",
          },
          {
            validator: (_, value) => {
              const completedAtISODate =
                form.getFieldValue("completedAt") &&
                new Date(
                  format(
                    new Date(form.getFieldValue("completedAt").toISOString()),
                    "dd-MMM-yyy"
                  )
                );
              const dueDateISODate =
                value &&
                new Date(format(new Date(value.toISOString()), "dd-MMM-yyy"));

              if (
                dueDateISODate &&
                !isAfter(dueDateISODate, completedAtISODate)
              ) {
                return Promise.reject("Date before 'Completed At' date");
              }

              return Promise.resolve();
            },
          },
        ]}
        required
      >
        <DatePicker
          data-testid="due-date-datepicker"
          onChange={(date) =>
            form.setFields([{ value: date, name: "dueDate" }])
          }
          style={{ width: "100%" }}
          placeholder="Due Date"
        />
      </Form.Item>
      <Form.Item
        label="Charge Type:"
        name="chargeType"
        required
        rules={[
          {
            required: true,
            message: "",
          },
        ]}
        style={{ marginBottom: 0 }}
      >
        <Radio.Group
          data-testid="charge-type-select"
          style={{ backgroundColor: "#F8F8F8", padding: "10px" }}
        >
          <Radio
            data-testid="additional-charge-option"
            value="ADDITIONAL_CHARGE"
          >
            Additional Charge
          </Radio>
          <Radio
            data-testid="visit-activity-option"
            value="VISIT_ACTIVITY_CHARGE"
          >
            Visit Activity Charge
          </Radio>
        </Radio.Group>
      </Form.Item>
      <section
        style={{
          backgroundColor: "#F8F8F8",
          padding: showOtherFormItems ? "0" : "10px",
        }}
      >
        <Form.Item
          style={{ marginBottom: 0 }}
          label="Category:"
          name="category"
          rules={[
            {
              required: !showOtherFormItems,
              message: "",
            },
          ]}
          hidden={showOtherFormItems}
        >
          <Select
            data-testid="additional-charges-category-select"
            placeholder="Choose a Category"
            options={categoryOptions}
          />
        </Form.Item>
      </section>
      <section
        style={{
          backgroundColor: "#F8F8F8",
          padding: !showOtherFormItems ? "0" : "10px",
        }}
      >
        <Form.Item
          required
          label="Subject ID:"
          name="subjectId"
          rules={[
            {
              required: showOtherFormItems,
              message: "",
            },
          ]}
          hidden={!showOtherFormItems}
        >
          <Input data-testid="subject-id-input" />
        </Form.Item>
        <Form.Item
          required
          label="Visit Name:"
          name="protocolVisitCrossVersionId"
          style={{ marginBottom: 0 }}
          rules={[
            {
              required: showOtherFormItems,
              message: "",
            },
          ]}
          hidden={!showOtherFormItems}
        >
          <Select
            disabled={!protocolVersionId}
            data-testid="site-trial-visit-select"
            placeholder="Choose a visit name"
            options={visitForProtocolVersionOptions}
          />
        </Form.Item>
      </section>
      <Form.Item
        label="Additional Options:"
        valuePropName="checked"
        name="invoiceable"
        style={{ marginTop: 16 }}
      >
        <Checkbox
          checked={isInvoiceable}
          data-testid="activity-invoiceable-checkbox"
        >
          Invoiceable
        </Checkbox>
      </Form.Item>
    </Form>
  );
};
