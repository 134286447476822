import {
  GetProtocolForSiteTrialDocument,
  useAccountsReceivableSourceEventsQuery,
} from "@app/service/generated";
import { useSelectedSite } from "./sites";
import { useQueries } from "react-query";
import { useCodegenFetcher } from "@app/service/codegenFetcher";
import { lineItems } from "@model/accountsReceivable";

export function useAccountsReceivable() {
  const {
    data: siteData,
    isFetching: siteDataFetching,
    isLoading: isSiteDataLoading,
  } = useSelectedSite();

  const arLineItemVars = {
    siteId: siteData?.selectedSite?.value ?? "",
  };

  const {
    data: summary,
    isFetching: lineItemsFetching,
    isLoading: areLineItemsLoading,
  } = useAccountsReceivableSourceEventsQuery(arLineItemVars, {
    enabled: !!siteData,
    retry: 1,
    staleTime: 1000 * 10, // ten sec
  });

  const sourceEvents = summary
    ? summary.Financials2__accountsReceivable.summary3.sourceEvents
    : [];

  const sourceEventProtocolAndSiteTrialIds = sourceEvents.map(
    (sourceEvent) => ({
      siteTrial: { id: sourceEvent.siteTrial.id },
    })
  );

  // hack to get equality by value
  const distinctProtocolAndSiteTrialIds =
    sourceEventProtocolAndSiteTrialIds.reduce(
      (acc, li) => acc.add(li.siteTrial.id),
      new Set<string>()
    );

  const protocolFetcher = useCodegenFetcher(GetProtocolForSiteTrialDocument);

  const protocolResults = useQueries(
    [...distinctProtocolAndSiteTrialIds].map((siteTrialId) => {
      return {
        staleTime: 1000 * 10, // ten sec
        enabled: !!siteData,
        queryKey: ["protocol", siteTrialId],
        queryFn: () => protocolFetcher({ siteTrialId }),
      };
    })
  );

  const protocols = protocolResults
    .filter((r) => r.isSuccess)
    .map(
      (r) =>
        (r.data as IGetProtocolForSiteTrialQuery)
          .Financials2__getSiteTrialProtocol
    );

  const protocolsLoaded = protocolResults.every((r) => r.isSuccess);

  return {
    siteId: siteData?.selectedSite?.value,
    isLoading: isSiteDataLoading || areLineItemsLoading || !protocolsLoaded,
    isFetching: siteDataFetching || lineItemsFetching,
    reactQueryKey: arLineItemVars,
    lineItems: (protocolsLoaded && lineItems(sourceEvents, protocols)) || [],
  };
}
