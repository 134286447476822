import { getVisitsFromSchedule } from "@app/model/protocols";
import {
  getBudgetConfigurationProtocolVersion,
  getProtocolSchedule,
  isHoldbackEnabled,
  isOverheadEnabled,
  MatrixBodyDerivationInput,
} from "@model/budgets";

function groupArrayToMap<K, V>(arr: V[], keySelector: (ele: V) => K) {
  return arr.reduce((accum, ele) => {
    const key = keySelector(ele);
    const curr = accum.get(key) || [];
    return accum.set(key, [...curr, ele]);
  }, new Map<K, V[]>());
}

/**
 * @deprecated
 */
export const deriveMatrixBody = ({
  protocol,
  budget,
  budgetConfigVersionId,
}: MatrixBodyDerivationInput): BudgetMatrixBody => {
  const budgetConfigVersion = budget.configVersions.find((configVersion) => {
    return configVersion.id === budgetConfigVersionId;
  });
  if (!budgetConfigVersion) {
    throw new Error("Couldn't find budgetConfigVersionId within budget");
  }

  const selectedProtocol =
    getBudgetConfigurationProtocolVersion(budgetConfigVersion);

  const protocolSchedule = getProtocolSchedule(
    protocol,
    selectedProtocol?.protocolVersionId
  );

  if (!protocolSchedule) {
    throw new Error("No schedule found for protocol version");
  }

  const protocolVersionSchedule = protocolSchedule;

  const distinctTracks = new Set(
    getVisitsFromSchedule(protocolVersionSchedule).map((v) =>
      v.unscheduled ? "Unscheduled Visit" : v.track || "No Track"
    )
  );
  const sortedTracks = Array.from(distinctTracks).sort();

  const trackGroupedVisits = groupArrayToMap(
    getVisitsFromSchedule(protocolVersionSchedule),
    (v) => (v.unscheduled ? "Unscheduled Visit" : v.track || "No Track")
  );
  const visitGroupedVisitActivities = groupArrayToMap(
    protocolVersionSchedule.siteAssociations || [],
    (va) => va.visitCrossVersionId
  );

  /*
   * Pre-mature optimization?:
   * We could do Array.from(trackGroupedVisits.entries()) and reduce over a tuple.
   * However, given the peformance characteristics of this function,
   * I tried to reduce the need for unnecessary intermediate data structures
   */

  const visitActivitiesByTrack = sortedTracks.reduce((accum, track) => {
    const visits: BudgetMatrixProtocolVersionScheduleVisit[] =
      trackGroupedVisits.get(track) || [];
    const visitActivities = visits.flatMap((v) =>
      visitGroupedVisitActivities.has(v.crossVersionId)
        ? visitGroupedVisitActivities.get(v.crossVersionId)!
        : []
    );
    return accum.set(track, visitActivities);
  }, new Map<string, BudgetMatrixProtocolVersionScheduleSiteAssociation[]>());

  const activityIdToProtocolActivity =
    protocolVersionSchedule.siteActivities!.reduce(
      (accum, sa) => accum.set(sa.crossVersionId!, sa),
      new Map<string, BudgetMatrixProtocolVersionScheduleSiteActivity>()
    );

  const activityIdsByTrack = sortedTracks.reduce((accum, track) => {
    const visitActivities: BudgetMatrixProtocolVersionScheduleSiteAssociation[] =
      visitActivitiesByTrack.get(track) || [];
    const ids = new Set(visitActivities.map((va) => va.activityCrossVersionId));
    return accum.set(track, Array.from(ids));
  }, new Map<string, string[]>());

  const visitIdToBudgetVisit = budgetConfigVersion.visits.reduce(
    (accum, visit) => accum.set(visit.id, visit), // visit.protocolVisitCrossVersionId?
    new Map<string, BudgetMatrixBudgetVisit>()
  );

  const activityIdToBudgetActivity = budgetConfigVersion.activities.reduce(
    (accum, activity) => accum.set(activity.id, activity), // activity.protocolActivityCrossVersionId?
    new Map<string, BudgetMatrixBudgetActivity>()
  );

  const budgetVisitActivityIndex = budgetConfigVersion.visitActivities.reduce(
    (accum, visitActivity) => {
      const visitId = visitActivity.visit.id;
      const activityId = visitActivity.activity.id;
      const visitEntry =
        accum.get(visitId) ||
        new Map<string, BudgetMatrixBudgetVisitActivity>();
      const updatedVisitEntry = visitEntry.set(activityId, visitActivity);
      return accum.set(visitId, updatedVisitEntry);
    },
    new Map<string, Map<string, BudgetMatrixBudgetVisitActivity>>()
  );

  const visitChargeTracks = sortedTracks.map((track) => {
    const visits: BudgetMatrixVisit[] = trackGroupedVisits
      .get(track)!
      .map((protocolVisit) => {
        const budgetVisit = visitIdToBudgetVisit.get(
          protocolVisit.crossVersionId!
        );

        return {
          crossVersionId: protocolVisit.crossVersionId!,
          name: protocolVisit.name ?? "",
          cost: budgetVisit?.cost || null,
          charge: budgetVisit?.charge || null,
          holdbackEnabled: isHoldbackEnabled({
            visit: budgetVisit,
            configVersion: budgetConfigVersion,
          }),
          overheadEnabled: isOverheadEnabled({
            visit: budgetVisit,
            configVersion: budgetConfigVersion,
          }),
        };
      });

    const activities: BudgetMatrixActivity[] = (
      activityIdsByTrack.has(track) ? activityIdsByTrack.get(track)! : []
    ).map((activityId) => {
      const budgetActivity = activityIdToBudgetActivity.get(activityId);

      return {
        crossVersionId: activityId,
        name: activityIdToProtocolActivity.get(activityId)?.name || "[Unknown]",
        invoiceable: budgetActivity?.invoiceable || false,
        holdbackEnabled: isHoldbackEnabled({
          activity: budgetActivity,
          configVersion: budgetConfigVersion,
        }),
        overheadEnabled: isOverheadEnabled({
          activity: budgetActivity,
          configVersion: budgetConfigVersion,
        }),
        overhead: budgetActivity?.overhead,
        cost: budgetActivity?.cost || null,
        charge: budgetActivity?.charge || null,
      };
    });

    const visitActivities: BudgetMatrixVisitActivity[] = (
      visitActivitiesByTrack.has(track) ? visitActivitiesByTrack.get(track) : []
    )!.map((va) => {
      const budgetActivity = activityIdToBudgetActivity.get(
        va.activityCrossVersionId
      );
      const budgetVisitActivity = budgetVisitActivityIndex
        .get(va.visitCrossVersionId)
        ?.get(va.activityCrossVersionId);

      return {
        visitCrossVersionId: va.visitCrossVersionId,
        activityCrossVersionId: va.activityCrossVersionId,
        invoiceable: budgetVisitActivity?.invoiceable ?? null,
        holdbackEnabled: isHoldbackEnabled({
          activity: budgetActivity,
          visitActivity: budgetVisitActivity,
          configVersion: budgetConfigVersion,
        }),
        overheadEnabled: isOverheadEnabled({
          activity: budgetActivity,
          visitActivity: budgetVisitActivity,
          configVersion: budgetConfigVersion,
        }),
        overhead: budgetVisitActivity?.overhead,
        defaultInvoiceable: budgetActivity?.invoiceable || false,
        cost: budgetVisitActivity?.cost || null,
        charge: budgetVisitActivity?.charge || null,
        defaultCost: budgetActivity?.cost || null,
        defaultCharge: budgetActivity?.charge || null,
        category: budgetVisitActivity?.category || "",
      };
    });

    return {
      id: track,
      name: track || "[Default]",
      visits,
      activities,
      visitActivities,
    };
  });

  return { visitCharges: { tracks: visitChargeTracks } };
};
