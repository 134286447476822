import { isPlainObject } from "lodash";

type Visit = {
  crossVersionId?: string | null;
  track?: string | null;
  name?: string | null;
  unscheduled?: boolean | null;
};

type ProtocolVisitSchedule = {
  visits?: Visit[] | null;
  siteVisits?: Visit[] | null;
};

export function getVisitsFromSchedule(
  schedule:
    | ProtocolVisitSchedule
    | { visits: [string: { name: string }]; siteVisits: undefined }
    | null
    | undefined
): Visit[] {
  if (!schedule) {
    return [];
  } else if (schedule.siteVisits) {
    return schedule.siteVisits;
  } else {
    if (isPlainObject(schedule.visits)) {
      return Object.entries(schedule.visits as [string: Visit]).map(
        ([k, v]) => {
          return { ...v, crossVersionId: k };
        }
      );
    } else {
      return schedule.visits ?? [];
    }
  }
}
