import { MatrixSlice, StoreCreator } from "../types";
import { deriveMatrixBody } from "@model/budgets/deprecated";

// These fields must be nullable because they will not
// be instantiated on application startup.
// We could, however, create some default object,
// if non-nullability is desired.
/**
 * @deprecated
 */
export const createMatrixSlice: StoreCreator<MatrixSlice> = (set) => ({
  matrixBody: null,
  visitActivitySidebarProps: null,
  activeEdit: {
    activity: null,
    visit: null,
  },
  populateMatrix: (protocol, budget, budgetConfigVersionId) =>
    set((_) => {
      const matrixBody = deriveMatrixBody({
        protocol,
        budget,
        budgetConfigVersionId,
      });

      return {
        matrixBody,
      };
    }),
  activateVisitActivitySidebar: (visitActivitySidebarProps) =>
    set((_) => ({ visitActivitySidebarProps })),
  updateSidebarActivity: (activity: BudgetMatrixActivity) =>
    set((state) => {
      if (state.visitActivitySidebarProps) {
        const sidebarWithNewActivity = {
          ...state.visitActivitySidebarProps,
          activity,
        };

        return { ...state, visitActivitySidebarProps: sidebarWithNewActivity };
      }

      return state;
    }),
  updateSidebarVisitActivity: (visitActivity: BudgetMatrixVisitActivity) =>
    set((state) => {
      if (state.visitActivitySidebarProps) {
        const sidebarWithNewActivityVisit = {
          ...state.visitActivitySidebarProps,
          visitActivity,
        };

        return {
          ...state,
          visitActivitySidebarProps: sidebarWithNewActivityVisit,
        };
      }

      return state;
    }),
  updateSidebarVisit: (visit: BudgetMatrixVisit) =>
    set((state) => {
      if (state.visitActivitySidebarProps) {
        const sidebarWithNewVisit = {
          ...state.visitActivitySidebarProps,
          visit,
        };

        return { ...state, visitActivitySidebarProps: sidebarWithNewVisit };
      }

      return state;
    }),
  clearVisitActivitySidebar: () =>
    set((_) => ({ visitActivitySidebarProps: null })),
  setActiveEditOptions: (
    crossVersionId: string | null,
    entity: "visit" | "activity"
  ) =>
    set((state) => ({
      ...state,
      ...{ activeEdit: { ...state.activeEdit, [entity]: crossVersionId } },
    })),
});
