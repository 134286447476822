import { minus, money, Money, toNumber } from "@lib/currency";
import { isBoolean } from "lodash";
import {
  getProtocolSchedule,
  isHoldbackEnabled,
  isOverheadEnabled,
  sumChargesWithQuantity,
  sumCostsWithQuantity,
} from "@model/budgets";
import { getVisitsFromSchedule } from "@app/model/protocols";

// Low level interfaceBudgetSummary
export type BudgetConfigVersion = BudgetSummary["configVersions"][number];
export type Protocol = BudgetSummary["protocol"];

type BudgetStatistics = {
  totalCost: Money;
  totalCharge: Money;
  margin: Money;
};

export function isInvoicedSeparately(cell: BudgetMatrixNonEmptyCell) {
  if (isBoolean(cell.invoiceable)) {
    return cell.invoiceable;
  } else if (isBoolean(cell.activity.invoiceable)) {
    return cell.activity.invoiceable;
  } else {
    return false;
  }
}

function visitSubTotalColumn(
  budgetAggregate: BudgetAggregate,
  visitId: string
): { cost: Money | null; charge: Money | null } {
  const specificVisitColumn = budgetAggregate.matrix.columns.find(
    (column) => column.visitCrossVersionId === visitId
  );

  if (!specificVisitColumn) {
    return { cost: null, charge: null };
  } else if (specificVisitColumn.charge || specificVisitColumn.cost) {
    return {
      cost: specificVisitColumn.cost,
      charge: specificVisitColumn.charge,
    };
  } else {
    const totalCellCost = specificVisitColumn.cells.reduce(
      (columnSum, cell) => {
        if (cell.cost && !isInvoicedSeparately(cell)) {
          // visit activity override
          return columnSum + toNumber(cell.cost);
        } else if (cell.activity?.cost && !isInvoicedSeparately(cell)) {
          // default activity charge
          return columnSum + toNumber(cell.activity.cost);
        } else {
          // no cost/charge for cell
          return columnSum;
        }
      },
      0
    );

    const totalCellCharge = specificVisitColumn.cells.reduce(
      (columnSum, cell) => {
        if (cell.charge && !isInvoicedSeparately(cell)) {
          // visit activity override
          return columnSum + toNumber(cell.charge);
        } else if (cell.activity?.charge && !isInvoicedSeparately(cell)) {
          // default activity charge
          return columnSum + toNumber(cell.activity.charge);
        } else {
          // no cost/charge for cell
          return columnSum;
        }
      },
      0
    );

    return {
      cost: totalCellCost > 0 ? money(totalCellCost.toString()) : null,
      charge: totalCellCharge > 0 ? money(totalCellCharge.toString()) : null,
    };
  }
}

function visitSubTotalOverride(
  budgetAggregate: BudgetAggregate,
  visitId: string
) {
  const specificVisitColumn = budgetAggregate.matrix.columns.find(
    (column) => column.visitCrossVersionId === visitId
  );

  if (specificVisitColumn?.hasBudgetConfig) {
    return {
      cost: specificVisitColumn.cost ? money(specificVisitColumn.cost) : null,
      charge: specificVisitColumn.charge
        ? money(specificVisitColumn.charge)
        : null,
    };
  } else {
    return null;
  }
}

function visitSubTotalInvoicedSeparately(
  budgetAggregate: BudgetAggregate,
  visitId: string
): { cost: Money | null; charge: Money | null } {
  const specificVisitColumn = budgetAggregate.matrix.columns.find(
    (column) => column.visitCrossVersionId === visitId
  );

  if (specificVisitColumn) {
    const totalCost = specificVisitColumn.cells.reduce((columnSum, cell) => {
      if (cell.cost && isInvoicedSeparately(cell)) {
        // visit activity override
        return columnSum + toNumber(cell.cost);
      } else if (cell.activity?.cost && isInvoicedSeparately(cell)) {
        // default activity charge
        return columnSum + toNumber(cell.activity.cost);
      } else {
        // no cost/charge for cell
        return columnSum;
      }
    }, 0);

    const totalCharge = specificVisitColumn.cells.reduce((columnSum, cell) => {
      if (cell.charge && isInvoicedSeparately(cell)) {
        // visit activity override
        return columnSum + toNumber(cell.charge);
      } else if (cell.activity?.charge && isInvoicedSeparately(cell)) {
        // default activity charge
        return columnSum + toNumber(cell.activity.charge);
      } else {
        // no cost/charge for cell
        return columnSum;
      }
    }, 0);

    return {
      cost: totalCost > 0 ? money(totalCost.toString()) : null,
      charge: totalCharge > 0 ? money(totalCharge.toString()) : null,
    };
  } else {
    return { cost: null, charge: null };
  }
}

interface VisitSubTotal {
  column: { cost: Money | null; charge: Money | null };
  override: { cost: Money | null; charge: Money | null } | null;
  holdbackEnabled: boolean;
  overheadEnabled: boolean;
  invoicedSeparately: { cost: Money | null; charge: Money | null };
}

export function visitSubTotal(
  budgetAggregate: BudgetAggregate,
  visitId: string
): VisitSubTotal {
  return {
    column: visitSubTotalColumn(budgetAggregate, visitId),
    override: visitSubTotalOverride(budgetAggregate, visitId),
    holdbackEnabled: isHoldbackEnabled({ budgetAggregate, visitId }),
    overheadEnabled: isOverheadEnabled({ budgetAggregate, visitId }),
    invoicedSeparately: visitSubTotalInvoicedSeparately(
      budgetAggregate,
      visitId
    ),
  };
}

export function summary(
  visitCharges: BudgetStatistics,
  additionalCharges: BudgetStatistics
): BudgetStatistics {
  const totalCost =
    toNumber(visitCharges.totalCost) + toNumber(additionalCharges.totalCost);
  const totalCharge =
    toNumber(visitCharges.totalCharge) +
    toNumber(additionalCharges.totalCharge);
  const margin =
    toNumber(visitCharges.margin) + toNumber(additionalCharges.margin);

  return {
    totalCost: money(totalCost.toString()),
    totalCharge: money(totalCharge.toString()),
    margin: money(margin.toString()),
  };
}

export function visitCharges(
  budgetAggregate: BudgetAggregate
): BudgetStatistics {
  const totalCost = visitCostTotal(budgetAggregate);
  const totalCharge = visitChargesTotal(budgetAggregate);

  return {
    totalCost,
    totalCharge,
    margin: margin(totalCost, totalCharge),
  };
}

export function additionalCharges(
  budgetAggregate: BudgetAggregate
): BudgetStatistics {
  const totalCost = additionalChargeCostTotal(budgetAggregate);
  const totalCharge = additionalChargeChargeTotal(budgetAggregate);

  return {
    totalCost,
    totalCharge,
    margin: margin(totalCost, totalCharge),
  };
}

function additionalChargeCostTotal(budgetAggregate: BudgetAggregate) {
  return money(
    budgetAggregate.additionalCharges.lineItems
      .reduce(sumCostsWithQuantity, 0)
      .toString()
  );
}

function additionalChargeChargeTotal(budgetAggregate: BudgetAggregate) {
  return money(
    budgetAggregate.additionalCharges.lineItems
      .reduce(sumChargesWithQuantity, 0)
      .toString()
  );
}

function margin(totalCost: Money, totalCharge: Money): Money {
  return minus(totalCharge, totalCost);
}

function visitChargesTotal(budgetAggregate: BudgetAggregate) {
  return money(
    budgetAggregate.matrix.columns
      .reduce((totalSum, column) => {
        if (column.charge) {
          // count only invoiced separate items even on custom sub-total per visit
          const invoicedSeparately = column.cells.reduce((columnSum, cell) => {
            if (cell.charge && isInvoicedSeparately(cell)) {
              // visit activity override
              return (
                columnSum +
                toNumber(cell.charge) +
                toNumber(cell.overhead?.money ?? 0)
              );
            } else if (cell.activity?.charge && isInvoicedSeparately(cell)) {
              // default activity charge
              return (
                columnSum +
                toNumber(cell.activity.charge) +
                toNumber(cell.activity.overhead?.money ?? 0)
              );
            } else {
              // no cost/charge for cell
              return columnSum;
            }
          }, 0);
          // visit override
          return (
            totalSum +
            invoicedSeparately +
            toNumber(column.charge) +
            toNumber(column.overhead?.money ?? 0)
          );
        } else {
          return (
            totalSum +
            column.cells.reduce((columnSum: number, cell: any) => {
              if (cell.charge) {
                // visit activity override
                return (
                  columnSum +
                  toNumber(cell.charge) +
                  toNumber(cell.overhead?.money ?? 0)
                );
              } else if (cell.activity?.charge) {
                // default activity charge
                return (
                  columnSum +
                  toNumber(cell.activity.charge) +
                  toNumber(cell.activity.overhead?.money ?? 0)
                );
              } else {
                // no cost/charge for cell
                return columnSum;
              }
            }, 0)
          );
        }
      }, 0)
      .toString()
  );
}

function visitCostTotal(budgetAggregate: BudgetAggregate) {
  return money(
    budgetAggregate.matrix.columns
      .reduce((totalSum, column) => {
        // visit overide
        if (column.cost) {
          // count only invoiced separate items even on custom sub-total per visit
          const invoicedSeparately = column.cells.reduce((columnSum, cell) => {
            if (cell.cost && isInvoicedSeparately(cell)) {
              // visit activity override
              return columnSum + toNumber(cell.cost);
            } else if (cell.activity?.cost && isInvoicedSeparately(cell)) {
              // default activity charge
              return columnSum + toNumber(cell.activity.cost);
            } else {
              // no cost/charge for cell
              return columnSum;
            }
          }, 0);

          // visit override
          return totalSum + invoicedSeparately + toNumber(column.cost);
        } else {
          return (
            totalSum +
            column.cells.reduce((columnSum, cell) => {
              if (cell.cost) {
                // visit activity override
                return columnSum + toNumber(cell.cost);
              } else if (cell.activity?.cost) {
                // default activity charge
                return columnSum + toNumber(cell.activity.cost);
              } else {
                // no cost/charge for cell
                return columnSum;
              }
            }, 0)
          );
        }
      }, 0)
      .toString()
  );
}

export function deriveBudgetAggregate(
  configVersion: BudgetConfigVersion,
  protocol: Protocol,
  selectedTrack: string | "all" | "unscheduled" | null,
  budgetId: string,
  siteTrialId: string
): BudgetAggregate {
  const schedule = getProtocolSchedule(
    protocol,
    configVersion.selectedProtocolVersionId
  );

  const cells = (schedule.siteAssociations || []).map((association) => {
    const foundVisitActivity = configVersion.visitActivities.filter(
      (visitActivity) =>
        visitActivity.activity.id === association.activityCrossVersionId &&
        visitActivity.visit.id === association.visitCrossVersionId
    );
    const foundActivity = configVersion.activities.filter(
      (activity) => activity.id === association.activityCrossVersionId
    );

    return {
      track: selectedTrack,
      hasBudgetVisitActivityConfig: Boolean(foundVisitActivity.length > 0),
      budgetId,
      budgetConfigVersionId: configVersion.id,
      siteTrialId,
      visitCrossVersionId: association.visitCrossVersionId,
      activityCrossVersionId: association.activityCrossVersionId,
      defaultCost: foundActivity[0]?.cost
        ? money(foundActivity[0]?.cost)
        : null,
      defaultCharge: foundActivity[0]?.charge
        ? money(foundActivity[0]?.charge)
        : null,
      defaultInvoiceable: foundActivity[0]?.invoiceable ?? false,
      holdbackEnabled: isHoldbackEnabled({
        visitActivity: foundVisitActivity[0],
        activity: foundActivity[0],
        configVersion: configVersion,
      }),
      overheadEnabled: isOverheadEnabled({
        visitActivity: foundVisitActivity[0],
        activity: foundActivity[0],
        configVersion: configVersion,
      }),
      holdbackPercentage: configVersion.holdback?.percentage,
      overheadPercentage: configVersion.overhead?.percentage,
      category: "",
      activity: {
        name: "FIXME",
        holdbackEnabled: false,
        overheadEnabled: false,
        crossVersionId: association.activityCrossVersionId,
        cost:
          foundActivity.length > 0 && foundActivity[0].cost
            ? money(foundActivity[0].cost)
            : null,
        charge:
          foundActivity.length > 0 && foundActivity[0].charge
            ? money(foundActivity[0].charge)
            : null,
        invoiceable:
          foundActivity.length > 0 ? foundActivity[0].invoiceable : null,
        overhead: foundActivity[0]?.overhead
          ? {
              ...foundActivity[0].overhead,
              money: foundActivity[0].overhead.money
                ? money(foundActivity[0].overhead.money)
                : null,
            }
          : null,
      },
      visit: {
        holdbackEnabled: false,
        overheadEnabled: false,
        crossVersionId: association.visitCrossVersionId,
      },
      cost:
        foundVisitActivity.length > 0 && foundVisitActivity[0].cost
          ? money(foundVisitActivity[0].cost)
          : null,
      charge:
        foundVisitActivity.length > 0 && foundVisitActivity[0].charge
          ? money(foundVisitActivity[0].charge)
          : null,
      invoiceable:
        foundVisitActivity.length > 0
          ? foundVisitActivity[0].invoiceable
          : null,
      overhead: foundVisitActivity[0]?.overhead
        ? {
            ...foundVisitActivity[0].overhead,
            money: foundVisitActivity[0].overhead.money
              ? money(foundVisitActivity[0].overhead.money)
              : null,
          }
        : null,
      editing: false,
      key: `cell-${association.visitCrossVersionId}-${association.activityCrossVersionId}`,
      empty: false,
      loading: false,
      name: "FIXME",
    };
  });

  const columns: BudgetMatrixColumn[] = (getVisitsFromSchedule(schedule) || [])
    .filter((visit) => {
      const s = selectedTrack === "No Track" ? null : selectedTrack;
      if (s === "unscheduled" || s === "Unscheduled Visit") {
        return visit.unscheduled;
      } else if (s === "all") {
        return true;
      }
      return visit.track === s;
    })
    .map((visit) => {
      const foundBudgetVisit = configVersion.visits.filter(
        (budgetVisit) => budgetVisit.id === visit.crossVersionId
      );
      const foundCells = cells.filter(
        (cell) => cell.visit.crossVersionId === visit.crossVersionId
      );

      const cost =
        foundBudgetVisit.length > 0 && foundBudgetVisit[0].cost
          ? money(foundBudgetVisit[0].cost)
          : null;

      const charge =
        foundBudgetVisit.length > 0 && foundBudgetVisit[0].charge
          ? money(foundBudgetVisit[0].charge)
          : null;

      const name = visit.name === "" ? (visit.name = null) : visit.name;

      const column: BudgetMatrixColumn = {
        visitCrossVersionId: visit.crossVersionId!,
        track: visit.track ?? "",
        budgetId,
        budgetConfigVersionId: configVersion.id,
        name: name ?? "Unscheduled Visit",
        cost,
        charge,
        holdbackEnabled: isHoldbackEnabled({
          visit: { holdbackEnabled: foundBudgetVisit[0]?.holdback?.enabled },
          configVersion: configVersion,
        }),
        overheadEnabled: isOverheadEnabled({
          visit: {
            overheadEnabled: foundBudgetVisit[0]?.overhead?.enabled,
          },
          configVersion: configVersion,
        }),
        overhead: foundBudgetVisit[0]?.overhead
          ? {
              ...foundBudgetVisit[0].overhead,
              money: foundBudgetVisit[0].overhead.money
                ? money(foundBudgetVisit[0].overhead.money)
                : null,
            }
          : null,
        hasBudgetConfig: Boolean(
          foundBudgetVisit.length > 0 && foundBudgetVisit[0].id
        ),
        cells: foundCells.map((cell) => ({
          ...cell,
          empty: false,
          ...{
            visit: {
              ...cell.visit,
              crossVersionId: visit.crossVersionId!,
              name: visit.name ?? "",
              track: visit.track ?? "",
              cost,
              charge,
            },
          },
        })),
        overridden: Boolean(cost || charge),
        editing: false,
        holdbackPercentage: configVersion.holdback?.percentage ?? null,
        overheadPercentage: configVersion.overhead?.percentage ?? null,
        key: visit.crossVersionId!,
      };

      return column;
    });

  const additionalChargesLineItems =
    configVersion.additionalCharges.lineItems.edges.map((edge) => {
      return {
        ...edge.node,
        cost: edge.node.cost ? money(edge.node.cost) : null,
        charge: edge.node.charge ? money(edge.node.charge) : null,
        overhead: edge.node.overhead,
      };
    });

  const visitIdsForTrack = new Set(columns.map((c) => c.visitCrossVersionId));
  const activityIdsForTrack = new Set(
    (schedule.siteAssociations ?? [])
      .filter((sa) => visitIdsForTrack.has(sa.visitCrossVersionId))
      .map((sa) => sa.activityCrossVersionId)
  );
  const activitiesForTrack = (schedule.siteActivities ?? []).filter((sa) =>
    activityIdsForTrack.has(sa.crossVersionId!)
  );

  const rows: BudgetMatrixRow[] = activitiesForTrack.map((activity) => {
    const foundBudgetActivity = configVersion.activities.filter(
      (budgetActivity) => budgetActivity.id === activity.crossVersionId
    );

    const cost =
      foundBudgetActivity.length > 0 && foundBudgetActivity[0].cost
        ? money(foundBudgetActivity[0].cost)
        : null;

    const charge =
      foundBudgetActivity.length > 0 && foundBudgetActivity[0].charge
        ? money(foundBudgetActivity[0].charge)
        : null;

    const activityCell: BudgetMatrixCell = {
      activityCrossVersionId: activity.crossVersionId!,
      hasBudgetVisitActivityConfig: false,
      holdbackEnabled: isHoldbackEnabled({
        activity: {
          holdbackEnabled: foundBudgetActivity[0]?.holdback?.enabled,
        },
        configVersion: configVersion,
      }),
      overheadEnabled: isOverheadEnabled({
        activity: {
          overheadEnabled: foundBudgetActivity[0]?.overhead?.enabled,
        },
        configVersion: configVersion,
      }),
      defaultCharge: null,
      defaultCost: null,
      defaultInvoiceable: false,
      category: "",
      empty: false,
      loading: false,
      track: selectedTrack,
      budgetId,
      budgetConfigVersionId: configVersion.id,
      siteTrialId,
      name: activity.name,
      activity: {
        name: activity.name,
        overheadEnabled: false,
        holdbackEnabled: false,
        crossVersionId: activity.crossVersionId!,
        cost: null,
        charge: null,
        invoiceable: null,
      },
      cost,
      charge,
      invoiceable:
        foundBudgetActivity.length > 0
          ? foundBudgetActivity[0].invoiceable
          : null,
      editing: false,
      key: activity.crossVersionId!,
      holdbackPercentage: configVersion.holdback?.percentage,
      overheadPercentage: configVersion.overhead?.percentage,
    };

    const visitActivityCells: BudgetMatrixCell[] = columns.map((column) => {
      const visitActivity = cells.find(
        (cell) =>
          cell.activityCrossVersionId === activity.crossVersionId &&
          cell.visitCrossVersionId === column.visitCrossVersionId
      );

      if (!visitActivity) {
        return {
          empty: true,
          key: `${column.visitCrossVersionId}-${activity.crossVersionId}`,
          visit: { name: column.name },
          activityCrossVersionId: activity.crossVersionId ?? undefined,
          visitCrossVersionId: column.visitCrossVersionId ?? undefined,
        };
      }

      return {
        ...visitActivity,
        empty: false,
        track: selectedTrack,
        budgetId,
        budgetConfigVersionId: configVersion.id,
        holdbackPercentage: configVersion.holdback?.percentage,
        overheadPercentage: configVersion.overhead?.percentage,
        activity: {
          ...activityCell,
          crossVersionId: activity.crossVersionId!,
        },
        visit: {
          ...visitActivity.visit,
          ...column,
          track: selectedTrack,
        },
      };
    });

    return {
      activityCrossVersionId: activity.crossVersionId!,
      key: activity.crossVersionId!,
      cells: [activityCell, ...visitActivityCells],
    };
  });

  // No math allowed in this function
  return {
    additionalCharges: { lineItems: additionalChargesLineItems },
    matrix: {
      rows,
      columns,
    },
  };
}

export interface BudgetAdditionalChargeLineItem {
  id: string;
  quantity: number | null;
  invoiceable: boolean | null;
  cost: Money | null;
  charge: Money | null;
  arCount?: number | null;
  holdback?: Pick<IFinancials2__Holdback, "enabled" | "money"> | null;
  overhead?: Pick<IFinancials2__Overhead, "enabled" | "money"> | null;
}

interface BudgetAdditionalCharges {
  lineItems: BudgetAdditionalChargeLineItem[];
}

export interface BudgetAggregate {
  matrix: BudgetMatrix;
  additionalCharges: BudgetAdditionalCharges;
}

export interface BudgetMatrixNonEmptyCell {
  empty: false;
  loading: boolean;
  name: string;
  track: string | null;
  siteTrialId: string;
  budgetId: string;
  visitCrossVersionId?: string;
  activityCrossVersionId: string;
  budgetConfigVersionId: string;
  holdbackEnabled: boolean;
  overheadEnabled: boolean;
  holdbackPercentage?: string | null;
  overheadPercentage?: string | null;
  defaultCost: Money | null;
  defaultCharge: Money | null;
  defaultInvoiceable: boolean;
  category: string;
  activity: {
    crossVersionId: string;
    cost: Money | null;
    charge: Money | null;
    invoiceable: boolean | null;
    overhead?: {
      enabled?: boolean | null;
      money?: Money | null;
    } | null;
    name: string;
    holdbackEnabled: boolean;
    overheadEnabled: boolean;
  };
  visit?: {
    track: string | null;
    crossVersionId: string;
    cost: Money | null;
    charge: Money | null;
    name: string;
    holdbackEnabled: boolean;
    overheadEnabled: boolean;
  };
  cost: Money | null;
  charge: Money | null;
  invoiceable: boolean | null;
  hasBudgetVisitActivityConfig: boolean;
  holdback?: {
    enabled?: boolean | null;
    money?: Money | null;
  } | null;
  overhead?: {
    enabled?: boolean | null;
    money?: Money | null;
  } | null;
  editing: boolean;
  key: string;
}

export interface BudgetMatrixEmptyCell {
  empty: true;
  key: string;
  visitCrossVersionId?: string;
  visit: { name: string };
  activityCrossVersionId?: string;
}

export type BudgetMatrixCell = BudgetMatrixNonEmptyCell | BudgetMatrixEmptyCell;

export interface BudgetMatrixColumn {
  name: string;
  visitCrossVersionId: string;
  track: string | null;
  cost: Money | null;
  charge: Money | null;
  budgetId?: string;
  budgetConfigVersionId?: string;
  hasBudgetConfig: boolean;
  holdbackEnabled: boolean;
  overheadEnabled: boolean;
  overhead?: { enabled?: boolean | null; money?: Money | null } | null;
  cells: BudgetMatrixNonEmptyCell[];
  overridden: boolean;
  holdbackPercentage: string | null;
  overheadPercentage: string | null;
  editing: boolean;
  key: string;
}

type BudgetMatrixActivityCell = BudgetMatrixNonEmptyCell;
type BudgetMatrixVisitActivityCell = BudgetMatrixCell;

export interface BudgetMatrixRow {
  activityCrossVersionId: string;
  key: string;
  cells: [BudgetMatrixActivityCell, ...BudgetMatrixVisitActivityCell[]];
}

// High level interface
export interface BudgetMatrix {
  columns: BudgetMatrixColumn[];
  rows: BudgetMatrixRow[];
}

export function isActivityMatch(row: BudgetMatrixRow, activityFilter: string) {
  if (!activityFilter) return true;

  return row.cells[0].activity.name
    .toLocaleLowerCase()
    .includes(activityFilter);
}

export function isVisitMatch(
  cell: Pick<BudgetMatrixCell, "visit">,
  visitFilter: string
) {
  if (!visitFilter) return true;

  return cell.visit!.name.toLocaleLowerCase().includes(visitFilter);
}

function budgetAggregateCacheKey({
  budgetConfigVersionId,
  track,
}: Record<string, any>): string {
  track = track ?? "No Track";
  return JSON.stringify([budgetConfigVersionId, track]);
}

export function updateBudgetAggregateCache(
  budgetAggregateCache: Record<string, BudgetAggregate>,
  options: {
    budgetConfigVersionId: string;
    track: string | null;
    budgetAggregate: BudgetAggregate;
  }
) {
  return {
    ...budgetAggregateCache,
    [budgetAggregateCacheKey(options)]: options.budgetAggregate,
  };
}

export function getCachedBudgetAggregate(
  budgetAggregateCache: Record<string, BudgetAggregate>,
  options: Record<string, any>
): BudgetAggregate | null {
  return budgetAggregateCache[budgetAggregateCacheKey(options)] ?? null;
}
